// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~overlayscrollbars/css/OverlayScrollbars.css';
@import '~icheck-bootstrap/icheck-bootstrap.css';
@import '~sweetalert2/dist/sweetalert2.min.css';
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
